import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import styled from '@emotion/styled'
import { Column, Row } from 'components/layout'
import CollectionContext from './CollectionContext'
import Group from './Group'

const StyledCollectionRow = styled.div({
  '.content, h1': {
    textAlign: 'left',
  },
})

const Collection = (props) => {
  const { groups, title } = props

  return (
    <Fragment>
      <Helmet>
        <body className='collection-view' />
      </Helmet>
      <CollectionContext.Provider value={props}>
        <StyledCollectionRow as={Row} center='xs'>
          <Column className='content' xs={12}>
            <Row center='xs'>
              <Column xs={11}>
                <h1>{title}</h1>
              </Column>

              <Column xs={12}>
                {groups.map((group, i) => {
                  return <Group key={i} {...group} />
                })}
              </Column>
            </Row>
          </Column>
        </StyledCollectionRow>
      </CollectionContext.Provider>
    </Fragment>
  )
}

export default Collection
