import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { styles as s } from 'stylesheet'
import { Column, Row } from 'components/layout'

const styles = () => css({
  'a, h2, h3': css(s.grey),

  'h2': {
    textTransform: 'uppercase',
    textDecoration: 'underline',
  },
  'h3': {
    fontStyle: 'italic',
  },

  '.content': {
    textAlign: 'left',
  },

  p: {
    textAlign: 'left',
    color: 'white',
  },
})

const Resources = (props) => {
  const { bibliography, className, resourcesList, title } = props

  return (
    <Row className={className} center='xs'>
      <Column className='content' xs={11}>
        <Row>
          <Column xs={12}>
            <h1>{title}</h1>
          </Column>

          <Column xs={6}>
            <Row>
              <Column xs={12}>
                <h2>Resources</h2>
              </Column>
              {resourcesList.map((resource, i) => {
                const { resources, title } = resource
                return (
                  <Column xs={12} key={i}>
                    <Row>
                      <Column xs={12}>
                        <h3>{title}</h3>
                      </Column>

                      {resources.map((r, index) => {
                        const { body, url, title: resourceTitle } = r
                        return (
                          <Column xs={12} key={index}>
                            <a href={url} title={resourceTitle} target='_blank' rel='noopener noreferrer'>
                              {resourceTitle}
                            </a>
                            <p>{body}</p>
                          </Column>
                        )
                      })}
                    </Row>
                  </Column>
                )
              })}
            </Row>
          </Column>

          <Column xs={6}>
            <Row>
              <Column xs={12}>
                <h2>Bibliography</h2>
              </Column>
              {bibliography.map((bib, i) => {
                return (
                  <Column xs={12} key={i}>
                    {bib.note}
                  </Column>
                )
              })}
            </Row>
          </Column>
        </Row>
      </Column>
    </Row>
  )
}

export default styled(Resources)(styles)
