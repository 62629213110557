import React, { Component } from 'react'
import Navigation from 'components/Navigation'
import AboutPage from 'views/pages/About'
import ProjectPage from 'views/pages/Project'
import ResourcesPage from 'views/pages/Resources'
import Collection from 'views/Collection'
import Item from 'views/Item'
import Preview from './Preview'
import './setup'
import config from './config'

class Admin extends Component {
  componentDidMount = () => {
    const NetlifyCMS = require('netlify-cms')
    const { default: CMS, init } = NetlifyCMS
    CMS.init = init

    console.log(`CMS [${process.env.NODE_ENV}]`)
    if (process.env.NODE_ENV === 'development') {
      const { FileSystemBackend } = require('netlify-cms-backend-fs')
      CMS.registerBackend('file-system', FileSystemBackend)
    }

    CMS.registerPreviewStyle('https://cdnjs.cloudflare.com/ajax/libs/flexboxgrid/6.3.1/flexboxgrid.min.css')
    CMS.registerPreviewStyle('/cms.css')

    CMS.registerPreviewTemplate('navigation', (props) => <Preview {...props} component={Navigation} />)
    CMS.registerPreviewTemplate('about-assotto', (props) => <Preview {...props} component={AboutPage} />)
    CMS.registerPreviewTemplate('project', (props) => <Preview {...props} component={ProjectPage} />)
    CMS.registerPreviewTemplate('resources', (props) => <Preview {...props} component={ResourcesPage} />)
    CMS.registerPreviewTemplate('collections', (props) => <Preview {...props} component={Collection} />)
    CMS.registerPreviewTemplate('items', (props) => <Preview {...props} component={Item} />)
    CMS.registerPreviewTemplate('videoItems', (props) => <Preview {...props} component={Item} />)
    CMS.registerPreviewTemplate('galleryItems', (props) => <Preview {...props} component={Item} />)
    CMS.registerPreviewTemplate('audioItems', (props) => <Preview {...props} component={Item} />)

    CMS.init({ config })
  }

  render = () => {
    return (
      <div id='nc-root' />
    )
  }
}



export default Admin
