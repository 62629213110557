import React, { Fragment } from 'react'
import { Column, Row } from 'components/layout'
import { FiPlay } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { styles as s } from 'stylesheet'
import { withCollection } from './CollectionContext'

const StyledItemRow = styled.div({
  textAlign: 'left',
  paddingTop: '0.625rem',
  paddingBottom: '0.625rem',

  '.media-column': css(s.flex, s.alignCenter),

  '.poster': {
    width: '2.8125rem',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginRight: '20%',

    '&:after': css(s.block, {
      content: '""',
      paddingBottom: '100%',
    }),
  },

  svg: {
    height: '1.25em',
    width: '1.25em',
    position: 'relative',
    strokeWidth: '2',
    stroke: 'rgb(201,198,215)',
  },

  '.last': {
    textAlign: 'right',
  },
})

const Item = (props) => {
  const {
    collection: { tableFields },
    itemType,
    name,
    poster,
    title,
  } = props

  return (
    <StyledItemRow as={Row} middle='xs'>
      <Column xs>
        <div className='media-column'>
          <div
            className='poster'
            style={{ backgroundImage: poster ? `url(${poster})` : 'none' }}
          />

          {itemType !== 'gallery' && (
            <FiPlay />
          )}
        </div>
      </Column>

      <Column xs>
        {title}
      </Column>

      {tableFields && tableFields.map((field, fieldIndex) => {
        const value = props[field.key]
        const className = tableFields.length - 1 === fieldIndex ? 'last' : ''

        if (!value) {
          return <Column key={field.key} className={className} xs />
        }

        if (typeof value === 'string') {
          return (
            <Column key={field.key} className={className} xs>
              <Link to={`/item/${name}/`}>
                {value}
              </Link>
            </Column>
          )
        }

        return (
          <Column key={field.key} className={className} xs>
            {value.map((vItem, vIndex) => (
              <Fragment key={vIndex}>
                {vIndex === 0 ? vItem.value : `, ${vItem.value}`}
              </Fragment>
            ))}
          </Column>
        )
      })}
    </StyledItemRow>
  )
}

export default withCollection(Item)
