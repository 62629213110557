import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { styles as s } from 'stylesheet'
import { Column, Row } from 'components/layout'
import Markdown from 'components/Markdown'

const styles = () => css({

  '.header': {
    textAlign: 'center',
    marginBottom: '2rem',
  },

  '.content': {
    textAlign: 'left',
    marginBottom: '2rem',
  },

  '.caption': css(s.inlineBlock, {
    fontSize: '.75rem',
    color: 'rgb(100,100,100)',
    transformOrigin: 'left',
    paddingTop: '1rem',
  }),
})

const AboutPage = (props) => {
  const { body, className, title, featuredImage, featuredImageCaption } = props

  return (
    <Row className={className} center='xs'>
      <Column className='content' xs={11}>
        <Row>
          <Column xs={12}>
            <h1 className='header'>{title}</h1>
          </Column>
          <Column xs={12}>
            <Row>
              <Column xs={5}>
                <img src={featuredImage} alt={title} />
                <p className='caption'>{featuredImageCaption}</p>
              </Column>

              <Column xs={4} xsOffset={1}>
              <div className='mainContent'>
                <Markdown>{body}</Markdown>
              </div>
              </Column>
            </Row>
          </Column>
        </Row>
      </Column>
    </Row>
  )
}

export default styled(AboutPage)(styles)
