import React from 'react'
import { Link } from 'react-router-dom'
import find from 'lodash/find'
import data from 'data'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { styles as s } from 'stylesheet'
import Navigation from 'components/Navigation'
import { Row, Column } from 'components/layout'

const { settings } = data
const navigation = find(settings, set => set.name === 'navigation')
const { siteDescription } = find(settings, set => set.name === 'global')

const StyledHeaderRow = styled.div({
  textAlign: 'left',

  header: {
    padding: '2rem 0 4rem',
  },

  '.site-description': css(s.inlineBlock, {
    width: '75%',
    lineHeight: '150%',
  }),
})

const Header = (props) => (
  <StyledHeaderRow as={Row} className='header-row' center='xs'>
    <Column xs={11}>
      <header>
        <Row>
          <Column xs={6}>
            <Link className='site-description' to='/'>
              {siteDescription}
            </Link>
          </Column>
          <Column xs={6}>
            <Navigation {...navigation} />
          </Column>
        </Row>
      </header>
    </Column>
  </StyledHeaderRow>
)

export default Header
