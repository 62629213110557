import React from 'react'
import find from 'lodash/find'
import data from 'data'
import Item from 'views/Item'

const { items } = data

const ItemController = (props) => {
  const { match: { params: { item } } } = props

  const itemProps = find(items, i => i.name === item)

  return <Item {...itemProps} />
}

export default ItemController
