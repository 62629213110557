import React from 'react'
import { withItem } from './ItemContext'
import Audio from './Audio'
import Video from './Video'
import Gallery from './Gallery'

const Media = (props) => {
  const { itemType } = props.item

  const renderMediaPlayer = () => {
    switch (itemType) {
      case 'gallery':
        return <Gallery />
      case 'video':
        return <Video />
      default:
        return <Audio />
    }
  }

  return renderMediaPlayer()
}

export default withItem(Media)
