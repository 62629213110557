import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import find from 'lodash/find'
import styled from '@emotion/styled'
import { colors } from 'stylesheet'
import { Column, Row } from 'components/layout'
import data from 'data'
import { withCollection } from './CollectionContext'
import Item from './Item'

const { items: itemsCollection } = data

const StyledGroupRow = styled.div({
  marginBottom: '3rem',

  '.title-column': {
    textAlign: 'left',
  },

  '.item-row': {
    borderTop: `1px solid ${colors.black}`,
    cursor: 'pointer',
  },

  '.last': {
    textAlign: 'right',
  },
})

const StyledHeaderColumn = styled.div( {
  paddingBottom: '2rem',
  paddingTop: '2rem',
  textAlign: 'left',

  h2: {
    margin: 0,
  },
})

const Group = (props) => {
  const {
    collection: { tableFields },
    history: { push },
    items,
    title,
  } = props

  return (
    <StyledGroupRow as={Row} center='xs'>
      <Column xs={11}>
        <Row>
          <StyledHeaderColumn as={Column} xs>
            <h2 className='title'>{title}</h2>
          </StyledHeaderColumn>

          <StyledHeaderColumn as={Column} xs>
            Title
          </StyledHeaderColumn>

          {tableFields && tableFields.map((field, fieldIndex) => (
            <StyledHeaderColumn as={Column} key={field.key} xs className={tableFields.length - 1 === fieldIndex ? 'last' : ''}>
              {field.title}
            </StyledHeaderColumn>
          ))}
        </Row>
      </Column>

      {items && items.map((itm, itemIndex) => {
        const item = find(itemsCollection, it => it.title === itm.item)

        if (!item) {
          return <Fragment key={itemIndex} />
        }

        return (
          <Column
            className='item-row'
            xs={12}
            key={itemIndex}
            onClick={() => push(`/item/${item.name}/`)}
          >
            <Row center='xs'>
              <Column xs={11}>
                <Item {...item} />
              </Column>
            </Row>
          </Column>
        )
      })}
    </StyledGroupRow>
  )
}

export default withCollection(withRouter(Group))
