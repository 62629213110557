import { css } from '@emotion/core'
import colors from './colors'

const globals = {
  '*': {
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
  },
  'html, body': {
    fontSize: '16px',
    lineHeight: '131.25%',
    color: colors.black,
    backgroundColor: colors.white,
  },
  body: {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    lineHeight: '1.5',
    '&.home-view': {
      backgroundColor: colors.white,
    },

    '&.item-view': {
      backgroundColor: colors.black,
      color: colors.white,
      '.header-row': {
        backgroundColor: colors.black,
        color: colors.white,
        a: {
          color: colors.white,
        },
      },
    },

    '.content-container': {
      backgroundColor: colors.white,
    },
  },
  '.header-row': {
    color: colors.black,
    a: {
      color: colors.black,
      '&:hover': {
        color: colors.black,
        textDecoration: 'underline',
      }
    },
  },
  img: {
    maxWidth: '100%',
    display: 'block',
  },

  button: {
    backgroundColor: 'transparent',
    borderStyle: 'none',
  },

  a: {
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: colors.black,
      textDecoration: 'underline',
    },
    '&:active': {
      color: colors.grey,
      textDecoration: 'underline',
    }
  },

  'h1, h2, h3, h4, h5': {
    fontWeight: 'normal',
  },

  h1: {
    fontSize: '4em',
    paddingBottom: '2rem',
    lineHeight: '120%',
  },

  h2: {
    fontSize: '1rem',
    textTransform: 'uppercase',
    textDecoration: 'underline',
    marginBottom: '1.25rem',
  },

  '.container-fluid': {
    padding: '0 2rem',
  },

  b: {

  },

  video: {
    paddingBottom: '1rem',
    maxWidth: '50%',
  },

  '.body': {
    paddingTop: '3rem',
    textAlign: 'left',
  },

}

export default css(globals)
