import React, { createContext } from 'react'

const CollectionContext = createContext()

export const withCollection = Component => props => (
  <CollectionContext.Consumer>
    {collection => <Component {...props} collection={collection} />}
  </CollectionContext.Consumer>
)

export default CollectionContext
