import React, { createContext } from 'react'

const ItemContext = createContext()

export const withItem = Component => props => (
  <ItemContext.Consumer>
    {item => <Component {...props} item={item} />}
  </ItemContext.Consumer>
)

export default ItemContext
