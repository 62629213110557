import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { styles as s } from 'stylesheet'
import find from 'lodash/find'
import { Column, Row } from 'components/layout'
import Navigation from 'components/Navigation'
import data from 'data'

const Home = styled.div({
  '.image': css({
    height: '100vh',
    minHeight: '100vh',
    maxHeight: '90vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }),

  '.info': css(s.flex, s.flexColumn, s.spaceBetween, {
    height: '100%',
    fontSize: '1rem',
    padding: '2rem',
    textAlign: 'left',

    p: {
      fontSize: '1rem',
      width: '65%',
      textAlign: 'left',
    },

    h1: {
      fontSize: '6rem',
      lineHeight: '1',
      marginTop: '1rem',
    },
  }),
})

const { settings } = data
const navigation = find(settings, set => set.name === 'navigation')
const { homepage } = find(settings, set => set.name === 'global')

const Homepage = () => {
  return (
    <Fragment>
      <Helmet>
        <body className='home-view' />
      </Helmet>
      <Home as={Row} center='xs'>
        <Column xs={4}>
          <div className='info'>
            <div className='heading'>
              <p>{homepage.subtitle}</p>
              <h1>{homepage.title}</h1>
            </div>
            <Navigation {...navigation} />
          </div>
        </Column>
        <Column xs={6}>

          <div
            className='image'
            style={{
              backgroundImage: `url(${homepage.image})`,
            }}
          />
        </Column>
      </Home>
    </Fragment>
  )
}

export default Homepage
