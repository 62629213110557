import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Column, Row } from 'components/layout'
import Markdown from 'components/Markdown'

const styles = () => css({
  '.content': {
    textAlign: 'left',
  },

  '.body-class': {
    paddingRight: '2rem',
    paddingBottom: '3rem',
  },

  img: {
    position: 'relative',
    objectFit: 'cover',
    minWidth: '100%',
    maxWidth: '100%',
  },
})

const Project = (props) => {
  const { body, className, title, featuredImage } = props
  return (
    <Row className={className} center='xs'>
      <Column className='content' xs={11}>
        <Row>
          <Column xs={6}>
            <h1>{title}</h1>
            <div className='body-class'>
              <Markdown>{body}</Markdown>
            </div>
          </Column>
          <Column xs={6}>
            <img src={featuredImage} alt={title} />
          </Column>
        </Row>
      </Column>
    </Row>
  )
}

export default styled(Project)(styles)
