import React from 'react'
import { Column, Row } from 'components/layout'
import styled from '@emotion/styled'
import { colors } from 'stylesheet'

const StyledFooterRow = styled.div({
  footer: {
    padding: '6rem 0 1rem 0',
    fontSize: '.825rem',
    color: colors.grey,
  },

  '.credit': {
    textAlign: 'left',
  },
})

const Footer = (props) => {
  return (
    <StyledFooterRow as={Row} center='xs'>
      <Column xs={12} lg={10}>
        <footer>
          <Row>
            <Column className='credit' xs={2} >
              This site was made using
              <img src='/images/analog-archive-logo-gray.png' alt='AA-Logo' />
              An open-source tool for the thoughtful, engaged, and community-based use of analog materials moved, with some care, to the digital.
            </Column>
            <Column className='credit' xs={3} xsOffset={1}>
              In collaboration with:<br/>
              - Partner & Partners<br/>
              - Interference Archive<br/>
              - XFR Collective<br/>
              - Visual AIDS<br/>
              - VHS Archive Working Group @ CUNY Graduate Center
            </Column>
            <Column className='credit' xs={2} xsOffset={4}>
              With the support of:<br/>
              The Center for the Humanities
            </Column>
          </Row>

        </footer>
      </Column>
    </StyledFooterRow>
  )
}

export default Footer
