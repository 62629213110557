import React from 'react'
import moment from 'moment'
import momentDurationSetup from 'moment-duration-format'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Row, Column } from 'components/layout'
import { colors, styles as s } from 'stylesheet'
import { withItem } from './ItemContext'

momentDurationSetup(moment)

const formatSeconds = seconds => moment.duration(seconds, 'seconds').format('hh:mm:ss', { trim: true, stopTrim: 'mm:ss' })

const styles = () => css( {
  padding: '1rem 2rem',

  '.actions-column': {
    button: {
      cursor: 'pointer',
      backgroundColor: 'transparent',
        color: colors.white,

      i: css( {
        fontSize: '1rem',
        color: colors.white,
      }),
    },
  },

  '.timeline': css(s.alignCenter, s.flex, s.relative, { height: '4px' }),

  '.duration': {
    borderTop: `4px solid ${colors.white}`,
    width: '100%',
      color: colors.white,
  },
  '.playedSeconds': css(s.absolute, {
    borderRight: `4px solid ${colors.white}`,
    height: '15px',
  }),

  'input:focus': {
    outlineWidth: 0,
    outline: 'none',
  },

  'input[type="range"]': {
    appearance: 'none',
    width: '100%',
    userSelect: 'none',
  },

  'input::-webkit-slider-runnable-track': css( {
    height: '4px',
    backgroundColor: colors.white,
    cursor: 'pointer',
  }),

  'input::-webkit-slider-thumb': css( {
    borderRadius: 0,
    height: '15px',
    width: '4px',
    cursor: 'grab',
    appearance: 'none',
    backgroundColor: colors.white,
    marginTop: '-5.5px',
  }),

  p: {
      color: colors.white,
  },
})

const PlayerControls = (props) => {
  const { className, item: { player } } = props
  const { actions, state } = player
  const { duration, playing, played, playedSeconds } = state

  const playedSecondsFormatted = formatSeconds(playedSeconds)
  const durationSecondsFormatted = formatSeconds(duration)

  return (
    <Row className={`${className} player-controls`} middle='xs'>
      <Column className='actions-column' xs={1}>
        <button onClick={playing ? actions.handlePause : actions.handlePlay}>
          <i className={`fas fa-${playing ? 'pause' : 'play'}`} />
        </button>
      </Column>

      <Column className='timeline-column' xs={8} lg={9} xl={10}>
        <div className='timeline'>
          <input
            className='timeline-input'
            type='range' min={0} max={1} step='any'
            value={played}
            onMouseDown={actions.handleSeekMouseDown}
            onChange={actions.handleSeekChange}
            onMouseUp={actions.handleSeekMouseUp}
          />
        </div>
      </Column>

      <Column className='duration-column' xs={3} lg={2} xl={1}>
        <p>{`${playedSecondsFormatted}/${durationSecondsFormatted}`}</p>
      </Column>
    </Row>
  )
}

export default styled(withItem(PlayerControls))(styles)
