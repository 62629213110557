import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { styles as s } from 'stylesheet'
import { withItem } from './ItemContext'

const StyledResources = styled.div({
  marginBottom: '2rem',
})

const StyledRelated = styled.div({
  marginBottom: '2rem',

  img: css(s.block, {
    maxWidth: '100%',
    minWidth: '100%',
  }),

  p: {
    marginBottom: '0.25rem',
  },

  a: {
    marginBottom: '0.5rem',
  },
})

const StyledKeywords = styled.div({
  marginBottom: '2rem',
})

const Sidebar = (props) => {
  const { item } = props
  const { keywords, related, resources } = item
  return (
    <Fragment>
      {resources && resources.length > 0 && (
        <StyledResources>
          <b>Resources</b>
          <div>
            {resources.map((resource, resourceIndex) => {
              return (
                <a key={resourceIndex} href={resource.file} download>{resource.title}</a>
              )
            })}
          </div>
        </StyledResources>
      )}
      {related && related.length > 0 && (
        <StyledRelated>
          <b>Related</b>
          {related.map((section, sectionIndex) => {
            return (
              <div key={sectionIndex}>
                <p>{`${section.title}:`}</p>
                {section.items.map((item) => (
                  <Link key={item.name} to={`/item/${item.name}`}>
                    <img src={item.poster} alt={section.title} />
                  </Link>
                ))}
              </div>
            )
          })}
        </StyledRelated>
      )}
      {keywords && keywords.length > 0 && (
        <StyledKeywords>
          <h2>Keywords:</h2>
          <p>
            {keywords.map((keyword, index) => {
              let { value } = keyword
              if (keywords.length - 1 !== index) {
                value += ', '
              }
              return value
            })}
          </p>
        </StyledKeywords>
      )}
    </Fragment>
  )
}

export default withItem(Sidebar)
