import React, { Component, createRef, Fragment } from 'react'
import Slider from 'react-slick'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { styles as s } from 'stylesheet'
import { Column, Row } from 'components/layout'
import Markdown from 'components/Markdown'
import { withItem } from './ItemContext'

const arrowBase = {
  borderColor: 'transparent white',
  borderStyle: 'solid',
  height: 0,
  width: 0,
}

const ArrowLeft = styled.div(arrowBase, {
  borderWidth: '1rem 1rem 1rem 0',
})

const ArrowRight = styled.div(arrowBase, {
  borderWidth: '1rem 0 1rem 1rem',
})

const StyledGallery = styled.div({
  img: css(s.block, {
    width: 'auto',
    margin: '0 auto',
  }),

  '.gallery-track': {
    paddingBottom: '3rem',
  },

  button: {
    cursor: 'pointer',
  },

  '.hero-media': {
    img: {
      maxHeight: '50vh',
    },
  },

  '.slider-selector': css( {
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  }),

  '.selector-option': css(s.block, {
    cursor: 'pointer',

    img: {
      border: '3px solid transparent',
    },

    '&.active': {
      img: {
        border: '3px solid white',
      },
    },
  }),
})

const settings = {
  adaptiveHeight: true,
  arrows: false,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

class Gallery extends Component {
  constructor (props) {
    super(props)
    this.gallery = createRef()
  }

  state = {
    activeIndex: 0,
  }

  handlePrev = () => this.gallery.current.slickPrev()
  handleNext = () => this.gallery.current.slickNext()
  handleGoTo = (index) => this.gallery.current.slickGoTo(index)
  handleChange = (current, next) => this.setState({ activeIndex: next })

  renderImage = ({ caption, image }) => (
    <Row className='hero-media' middle='xs'>
      <Column xs={caption ? 9 : 12}>
        <img alt={caption} src={image} />
      </Column>
      {caption && (
        <Column xs={3}>
          <Markdown>{caption}</Markdown>
        </Column>
      )}
    </Row>
  )

  render = () => {
    const { item } = this.props
    const { media } = item
    const { activeIndex } = this.state

    if (!media || media.length === 0) {
      return <Fragment />
    }

    if (media.length === 1) {
      const item = media[0]

      if (!item || !item.image) {
        return <Fragment />
      }

      return <StyledGallery>{this.renderImage(item)}</StyledGallery>
    }

    return (
      <StyledGallery>
        <Row className='gallery-track' middle='xs'>
          <Column xs={1}>
            <button onClick={this.handlePrev}>
              <ArrowLeft />
            </button>
          </Column>

          <Column xs={10}>
            <Slider ref={this.gallery} {...settings} beforeChange={this.handleChange}>
              {media.map((item, index) => <div key={index}>{this.renderImage(item)}</div>)}
            </Slider>
          </Column>

          <Column xs={1}>
            <button onClick={this.handleNext}>
              <ArrowRight />
            </button>
          </Column>
        </Row>
        <Row className='slider-selector' center='xs'>
          <Column xs={11}>
            <Row middle='xs'>
              {media.map((item, index) => (
                <Column
                  xs={1}
                  className={`selector-option ${activeIndex === index ? 'active' : ''}`}
                  key={index}
                  onClick={() => this.handleGoTo(index)}
                >
                  <img alt={index} src={item.image} />
                </Column>
              ))}
            </Row>

          </Column>
        </Row>
      </StyledGallery>
    )
  }
}

export default withItem(Gallery)
