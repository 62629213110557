import { contentPath } from './constants'

const settingsPath = `${contentPath}/settings`

export default [
  {
    name: 'settings',
    label: 'Settings',
    delete: false,
    files: [
      {
        file: `${settingsPath}/navigation.yml`,
        label: 'Navigation',
        name: 'navigation',
        fields: [
          {
            label: 'Navigation Items',
            name: 'items',
            widget: 'list',
            types: [
              {
                label: 'Collection',
                name: 'collection',
                widget: 'object',
                fields: [
                  {
                    label: 'Item Collection',
                    name: 'collection',
                    widget: 'relation',
                    collection: 'collections',
                    searchFields: ['title', 'name'],
                    valueField: 'title',
                    displayFields: ['title'],
                  },
                ],
              },
              {
                label: 'Page',
                name: 'page',
                widget: 'object',
                fields: [
                  {
                    label: 'Page',
                    name: 'page',
                    widget: 'relation',
                    collection: 'pages',
                    searchFields: ['title', 'name'],
                    valueField: 'title',
                    displayFields: ['title'],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        file: `${settingsPath}/global.yml`,
        label: 'Global',
        name: 'global',
        fields: [
          {
            label: 'Site Title',
            name: 'siteTitle',
            widget: 'string',
          },
          {
            label: 'Site URL',
            name: 'siteUrl',
            widget: 'string',
          },
          {
            label: 'Site Description',
            name: 'siteDescription',
            widget: 'text',
          },
          {
            label: 'Homepage',
            name: 'homepage',
            widget: 'object',
            fields: [
              {
                label: 'Title',
                name: 'title',
                widget: 'string',
              },
              {
                label: 'Subtitle',
                name: 'subtitle',
                widget: 'text',
              },
              {
                label: 'Featured Image',
                name: 'image',
                widget: 'image',
              },
            ],
          },
        ],
      },
    ],
  },
]
