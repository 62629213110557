import React from 'react'
import find from 'lodash/find'
import data from 'data'
import Collection from 'views/Collection'

const { collections } = data

const CollectionController = (props) => {
  const { match: { params: { collection } } } = props

  const collectionProps = find(collections, p => p.name === collection)

  return <Collection {...collectionProps} />
}

export default CollectionController
