import React from 'react'
import { Switch, Route } from 'react-router-dom'
import find from 'lodash/find'
import data from 'data'
import AboutPage from 'views/pages/About'
import ProjectPage from 'views/pages/Project'
import ResourcesPage from 'views/pages/Resources'

const { pages } = data

const PageController = (props) => {
  const { match: { params: { page } } } = props

  const pageProps = find(pages, p => p.name === page)

  return (
    <Switch>
      <Route path='/page/about-the-project/' render={() => <AboutPage {...pageProps} />} />
      <Route path='/page/project/' render={() => <ProjectPage {...pageProps} />} />
      <Route path='/page/resources/' render={() => <ResourcesPage {...pageProps} />} />
    </Switch>
  )
}

export default PageController
