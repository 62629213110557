import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from 'react-flexbox-grid'

const Container = (props) => {
  return <Grid {...props} className={`${props.className}`} fluid />
}

Container.propTypes = {
  className: PropTypes.string,
}

Container.defaultProps = {
  className: '',
}

export default Container
