import React, { memo } from 'react'
import ReactPlayer from 'react-player'

const Player = memo((props) => {
  const { actions, playing, poster, media, muted, volume } = props

  return (
    <ReactPlayer
      ref={actions.handleLoad}
      onDuration={actions.handleDuration}
      onEnded={actions.handleEnded}
      onProgress={actions.handleProgess}
      onStart={actions.handlePlaying}
      onPlay={actions.handlePlay}
      onPause={actions.handlePause}
      url={media}
      playing={playing}
      volume={volume}
      muted={muted}
      width='100%'
      height='100%'
      config={{ file: { attributes: { poster } } }}
    />
  )
})

export default Player
