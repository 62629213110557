import React, { Component, createRef, Fragment } from 'react'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import momentDurationSetup from 'moment-duration-format'
import { filter, find, sortBy } from 'lodash'
import { Row, Column } from 'components/layout'
import Markdown from 'components/Markdown'
import data from 'data'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { colors } from 'stylesheet'
import Annotations from './Annotations'
import Media from './Media'
import ItemContext from './ItemContext'
import Sidebar from './Sidebar'

const StyledItem = styled.div({
  backgroundColor: colors.black,
  textAlign: 'left',

  '.media-header': css( {
    '.media-title': {
      textAlign: 'center',
      padding: '1rem 0 3rem 0',
      borderTop: 'dotted 1px',
    },
  }),

  '.body-column': {
    marginBottom: '2rem',
  },

  '.content': {
    borderTop: 'dotted 1px',
    margin: '4rem 0 0 0',
    padding: '4rem 0',
  },
})

momentDurationSetup(moment)
const { duration } = moment
const convertDuration = (time) => duration(time)

class Item extends Component {
  constructor () {
    super()
    this.player = createRef()
  }

  state = {
    duration: 0,
    paused: false,
    playing: false,
    seeking: false,
    volume: 0.8,
    muted: false,
    played: 0,
    loaded: 0,
    playbackRate: 1.0,
    playedSeconds: 0,
  }

  handleDuration = duration => this.setState({ duration })

  handleProgess = progress => {
    if (!this.state.seeking) {
      this.setState(progress)
    }
  }

  handleCaptionClick = seconds => {
    this.player.current.seekTo(seconds)
    this.setState({ playing: true })
  }

  handlePlay = () => this.setState({ playing: true })
  handlePause = () => this.setState({ playing: false })
  handleEnded = () => this.setState({ playing: this.state.loop })

  handleSeekMouseDown = e => {
    this.setState({ seeking: true })
  }
  handleSeekChange = e => {
    this.setState({ played: parseFloat(e.target.value) })
  }
  handleSeekMouseUp = e => {
    const newValue = parseFloat(e.target.value)
    this.setState({ seeking: false }, () => {
      this.player.current.seekTo(newValue)
    })
  }

  processAnnotations = (annotations, playedSeconds) => {
    if (!annotations || annotations.length === 0) {
      return annotations
    }

    const filteredAnnotations = filter(annotations, (ann) => (!!ann.start))
    const convertedAnnotations = filteredAnnotations.map((ann) => {
      const convertedStart = convertDuration(ann.start)
      const convertedEnd = convertDuration(ann.end)
      const startSeconds = convertedStart.asSeconds()
      return {
        ...ann,
        convertedEnd,
        convertedStart,
        active: startSeconds <= playedSeconds && playedSeconds < convertedEnd.asSeconds(),
        formattedStart: `[${convertedStart.format('hh:mm:ss', { trim: false })}]`,
      }
    })

    return sortBy(convertedAnnotations, 'convertedStart')
  }

  processRelated = (related) => {
    const filtered = filter(related, (section) => {
      if (!section.title || !section.items || section.items.length === 0 || (section.items.length === 1 && !section.items[0].item)) {
        return false
      }
      return true
    })

    const attached = filtered.map((section) => {
      const validItems = []
      section.items.forEach((iteratee) => {
        const foundItem = find(data.items, i => i.title === iteratee.item)
        if (foundItem) {
          validItems.push(foundItem)
        }
      })

      return {
        ...section,
        items: validItems,
      }
    })

    return filter(attached, (section) => section.items.length > 0)
  }

  processKeywords = (keywords) => filter(keywords, (key) => (!!key.value))

  render = () => {
    const { annotations, body, images, keywords, mediaFile, mediaUrl, related, resources, title } = this.props
    const { playedSeconds } = this.state
    const media = mediaFile || mediaUrl || images

    const processedAnnotations = this.processAnnotations(annotations, playedSeconds)
    const processedKeywords = this.processKeywords(keywords)
    const processedRelated = this.processRelated(related)

    const hasSidebar = (processedKeywords && processedKeywords.length > 0) || (processedRelated && processedRelated.length > 0) || (!!resources && resources.length > 0)
    const hasContent = !!hasSidebar || (processedAnnotations && processedAnnotations.length > 0) || !!body.trim()

    return (
      <Fragment>
        <Helmet>
          <body className='item-view' />
        </Helmet>
        <ItemContext.Provider
          value={{
            ...this.props,
            annotations: processedAnnotations,
            keywords: processedKeywords,
            related: processedRelated,
            player: {
              actions: {
                handleLoad: this.player,
                handleDuration: this.handleDuration,
                handleProgess: this.handleProgess,
                handleCaptionClick: this.handleCaptionClick,
                handlePlay: this.handlePlay,
                handlePause: this.handlePause,
                handleEnded: this.handleEnded,
                handleSeekChange: this.handleSeekChange,
                handleSeekMouseDown: this.handleSeekMouseDown,
                handleSeekMouseUp: this.handleSeekMouseUp,
              },
              state: this.state,
            },
            media,
          }}
        >
          <StyledItem as={Row} center='xs'>
            <Column xs={12}>
              <Row className='media-header' center='xs'>
                <Column className='media-title' xs={11}>
                  <h1>{title}</h1>
                </Column>
                <Column xs={12}>
                  {media && (
                    <Media />
                  )}
                </Column>
              </Row>
            </Column>
            {hasContent && (
              <Column className='content' xs={12}>
                <Row>
                  <Column xs={hasSidebar ? 6 : 8} smOffset={hasSidebar ? 2 : 1} >
                    <Row>
                      <Column className='body-column' xs={12}>
                        <Markdown>{body}</Markdown>
                      </Column>
                    </Row>
                    {processedAnnotations && processedAnnotations.length > 0 && (
                      <Annotations />
                    )}
                  </Column>
                  {hasSidebar && (
                    <Column xs={2} xsOffset={1}>
                      <Sidebar />
                    </Column>
                  )}
                </Row>
              </Column>
            )}
          </StyledItem>
        </ItemContext.Provider>
      </Fragment>
    )
  }
}

export default Item
