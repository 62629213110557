import itemCollections from './itemCollections'
import items from './items'
import pages from './pages'
import settings from './settings'

let backend = {
  name: 'git-gateway',
  repo: 'partnerandpartners/analog-archive',
}

if (process.env.NODE_ENV === 'development') {
  backend = {
    name: 'file-system',
    api_root: '/api',
  }
}

export default {
  backend,
  // display_url: "https://netlify-cms-react-example.netlify.com",
  media_folder: 'public/archive/media',
  public_folder: 'archive/media',
  collections: [
    ...itemCollections,
    ...pages,
    ...items,
    ...settings,
  ],
}
