import React from 'react'
import { NavLink } from 'react-router-dom'
import data from 'data'
import chunk from 'lodash/chunk'
import find from 'lodash/find'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { colors,styles as s } from 'stylesheet'
import { Column, Row } from 'components/layout'

const StyledNav = styled.nav({
  a: css(s.block, {
    lineHeight: '150%',
    cursor: 'pointer',
    '&:hover': {
      color: colors.black,
      textDecoration: 'underline',
    },
    '&.active': {
      color: colors.black,
      fontWeight: '500',
      textDecoration: 'underline',
    },
  }),
})

const getData = (type) => data[`${type}s`]

const Navigation = (props) => {
  const { items } = props
  const groups = chunk(items, 2)

  return (
    <StyledNav>
      <Row>
        {groups.map((group, groupIndex) => (
          <Column key={groupIndex} xs>
            {group.map((item, itemIndex) => {
              const navItem = find(getData(item.type), (dataItem) => dataItem.title === item[item.type])
              const { name, title } = navItem
              return (
                <NavLink key={itemIndex} to={`/${item.type}/${name}/`}>
                  {title}
                </NavLink>
              )
            })}
          </Column>
        ))}
      </Row>
    </StyledNav>
  )
}

Navigation.defaultProps = {
  items: [],
}

export default Navigation
