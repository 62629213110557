import { contentPath } from './constants'

const itemsPath = `${contentPath}/items`

const itemTypeOptions = ['audio', 'video', 'gallery']

const relatedFields = [
  {
    label: 'Related',
    name: 'related',
    widget: 'list',
    required: false,
    fields: [
      {
        label: 'Theme Title',
        name: 'title',
        widget: 'string',
      },
      {
        label: 'Items',
        name: 'items',
        widget: 'list',
        fields: [
          {
            label: 'Item',
            name: 'item',
            widget: 'relation',
            collection: 'items',
            searchFields: ['title', 'itemType'],
            valueField: 'title',
            displayFields: ['title', 'itemType'],
          },
        ],
      },
    ],
  },
]

const defaultItemFields = (itemType) => [
  {
    label: 'Title',
    name: 'title',
    widget: 'string',
  },
  {
    label: 'Type',
    name: 'itemType',
    widget: 'select',
    options: itemTypeOptions,
    default: itemType,
  },
  {
    label: 'Poster',
    name: 'poster',
    widget: 'image',
    required: false,
  },
  {
    label: 'Body',
    name: 'body',
    widget: 'markdown',
    required: false,
  },
  {
    label: 'Collections',
    name: 'collections',
    widget: 'list',
    required: false,
    fields: [
      {
        label: 'Collection Name',
        name: 'value',
        widget: 'string',
      },
    ],
  },
  {
    label: 'Resources',
    name: 'resources',
    widget: 'list',
    required: false,
    fields: [
      {
        label: 'Title',
        name: 'title',
        required: true,
        widget: 'string',
      },
      {
        label: 'File',
        name: 'file',
        required: true,
        widget: 'file',
        allow_multiple: false,
      },
    ],
  },
  {
    label: 'Keywords',
    name: 'keywords',
    widget: 'list',
    required: false,
    fields: [
      {
        label: 'Keyword',
        name: 'value',
        widget: 'string',
      },
    ],
  },
  {
    label: 'Date',
    name: 'date',
    widget: 'string',
    required: false,
  },
  ...relatedFields,
]

const annotationFields = [
  {
    label: 'Annotations',
    name: 'annotations',
    widget: 'list',
    required: false,
    fields: [
      {
        name: 'start',
        label: 'Timecode Start',
      },
      {
        name: 'end',
        label: 'Timecode End',
      },
      {
        name: 'body',
        label: 'Body',
        widget: 'markdown',
      },
      {
        name: 'images',
        label: 'Images',
        widget: 'list',
        fields: [
          {
            label: 'Image',
            name: 'image',
            widget: 'image',
          },
        ],
      },
      {
        name: 'files',
        label: 'Files',
        widget: 'list',
        fields: [
          {
            label: 'File Thumbnail',
            name: 'filethumb',
            widget: 'image',
          },
          {
            label: 'File',
            name: 'file',
            widget: 'file',
          },
        ],
      },
    ],
  },
]

const mediaFields = [
  {
    label: 'Media File',
    name: 'mediaFile',
    widget: 'file',
    required: false,
  },
  {
    label: 'Media URL',
    name: 'mediaUrl',
    widget: 'string',
    required: false,
  },
  {
    label: 'Length',
    name: 'length',
    widget: 'string',
    required: false,
  },
]

export default [
  {
    label: 'Audio Items',
    label_singular: 'Audio Item',
    name: 'audioItems',
    create: true,
    folder: itemsPath,
    filter: { field: 'itemType', value: 'audio' },
    fields: [
      ...defaultItemFields('audio'),
      ...annotationFields,
      ...mediaFields,
    ],
  },
  {
    label: 'Video Items',
    label_singular: 'Video Item',
    name: 'videoItems',
    create: true,
    folder: itemsPath,
    filter: { field: 'itemType', value: 'video' },
    fields: [
      ...defaultItemFields('video'),
      ...annotationFields,
      ...mediaFields,
    ],
  },
  {
    label: 'Gallery Items',
    label_singular: 'Gallery Item',
    name: 'galleryItems',
    create: true,
    folder: itemsPath,
    filter: { field: 'itemType', value: 'gallery' },
    fields: [
      ...defaultItemFields('gallery'),
      {
        label: 'Images',
        name: 'images',
        widget: 'list',
        fields: [
          {
            label: 'Image',
            name: 'image',
            widget: 'image',
          },
          {
            label: 'Caption',
            name: 'caption',
            widget: 'text',
            required: false,
          },
        ],
      },
    ],
  },
  {
    label: 'All Items',
    name: 'items',
    create: false,
    folder: itemsPath,
    fields: [
      ...defaultItemFields(),
    ],
  },
]
