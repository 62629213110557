import React from 'react'
import { string } from 'prop-types'
import showdown from 'showdown'
import styled from '@emotion/styled'

const StyledMarkdown = styled.div({
  p: {
    fontSize: '1rem',
    marginBottom: '1.25rem',

    '&:last-child': {
      marginBottom: 0,
    },
  },
})

const converter = new showdown.Converter()

const Markdown = (props) => {
  const { children } = props
  return <StyledMarkdown dangerouslySetInnerHTML={{ __html: converter.makeHtml(children) }} />
}

Markdown.propTypes = {
  children: string.isRequired,
}

export default Markdown
