import React from 'react'
import styled from '@emotion/styled'
import { Row, Column } from 'components/layout'
import Markdown from 'components/Markdown'

const StyledAnnotation = styled.div({
  marginBottom: '1rem',
  cursor: 'pointer',

  '&:hover': {
    opacity: '1 !important',
  },

  '.annotation-body': {
    marginBottom: '1rem',
  },

  '.image': {
    '&.active': {
      marginBottom: '1rem',
    },

    img: {
      minWidth: '100%',
    },
  },
})

const Annotation = ({ active, body, convertedStart, formattedStart, images, files, onClick, playedSeconds }) => {
  const startSeconds = convertedStart.asSeconds()

  return (
    <StyledAnnotation
      as={Row}
      onClick={() => onClick(startSeconds)}
      style={{ opacity: active ? 1 : 0.5 }}
    >
      <Column xs={3}>
        {formattedStart}
      </Column>
      <Column xs={9}>
        <Row>
          <Column
            className='annotation-body'
            xs={12}
          >
            <Markdown>
              {body}
            </Markdown>
          </Column>
          {images && images.length > 0 && images.map((i, index) => {
            return (
              <Column key={index} className={`${active ? 'active' : ''} image`} xs={active ? 6 : 4}>
                <img src={i.image} alt={index} />
              </Column>
            )
          })}
          {files && files.length > 0 && files.map((i, index) => {
            return (
              <Column key={index} className={`${active ? 'active' : ''} image`} xs={active ? 6 : 4}>
                <a href={i.file} alt={index}><img src={i.filethumb} alt={index}/></a>
              </Column>
            )
          })}
        </Row>
      </Column>
    </StyledAnnotation>
  )
}

export default Annotation
