import { contentPath } from './constants'
const collectionsPath = `${contentPath}/collections`

export default [
  {
    label: 'Item Collections',
    label_singular: 'Item Collection',
    name: 'collections',
    create: true,
    folder: collectionsPath,
    fields: [
      {
        label: 'Title',
        name: 'title',
        widget: 'string',
      },
      {
        label: 'Groups',
        name: 'groups',
        widget: 'list',
        required: false,
        fields: [
          {
            label: 'Group Title',
            name: 'title',
            widget: 'string',
          },
          {
            label: 'Items',
            name: 'items',
            widget: 'list',
            required: false,
            fields: [
              {
                label: 'Item',
                name: 'item',
                widget: 'relation',
                collection: 'items',
                searchFields: ['title', 'itemType'],
                valueField: 'title',
                displayFields: ['title', 'itemType'],
              },
            ],
          },
        ],
      },
      {
        label: 'Table Fields',
        name: 'tableFields',
        widget: 'list',
        fields: [
          {
            label: 'Title',
            name: 'title',
            widget: 'string',
          },
          {
            label: 'Key',
            name: 'key',
            widget: 'select',
            options: [
              // fields from items eligble for index table
              'length',
              'date',
              'collections',
              'keywords',
            ],
          },
        ],
      },
    ],
  },
]
