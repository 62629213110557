import React from 'react'
import { Container } from 'components/layout'

const Preview = (props) => {
  const { component: Component } = props
  const componentProps = props.entry.getIn(['data']).toJS()
  return (
    <Container>
      <Component {...componentProps} cms />
    </Container>
  )
}

export default Preview
