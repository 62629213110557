import React, { Fragment } from 'react'
import { Route, Switch} from 'react-router-dom'
import { Global } from '@emotion/core'
import Homepage from 'views/Homepage'
import Admin from 'views/Admin'
import Header from 'components/Header'
import Footer from 'components/Footer'
import { Column, Container, Row } from 'components/layout'
import PageController from 'controllers/PageController'
import CollectionController from 'controllers/CollectionController'
import ItemController from 'controllers/ItemController'
import globals from 'stylesheet/globals'
import 'react-flexbox-grid/dist/react-flexbox-grid.css'

const App = (props) => {
  return (
    <Fragment>
      <Switch>
        <Route path='/admin/' component={Admin} />
        <Route
          path='*'
          render={() => (
            <Container className={props.className}>
              <Global styles={globals} />
              <Row center='xs'>
                <Column className='content-container' xs={12} lg={11}>
                  <Switch>
                    <Route path='/admin' exact render={() => <Fragment />} />
                    <Route path='/' exact render={() => <Fragment />} />
                    <Route path='*' component={Header} />
                  </Switch>
                  <Switch>
                    <Route path='/page/:page/' component={PageController} />
                    <Route path='/collection/:collection/' component={CollectionController} />
                    <Route path='/item/:item/' component={ItemController} />
                    <Route path='/' exact component={Homepage} />
                  </Switch>
                </Column>
              </Row>
              <Switch>
                <Route path='/' exact render={() => <Fragment />} />
                <Route path='*' component={Footer} />
              </Switch>
            </Container>
          )}
        />
      </Switch>
      {/* NOTE: this is hidden so react-snap can crawl path without rendering */}
      {/* <Link style={{ display: 'none' }} to='/admin/'>admin</Link> */}
    </Fragment>
  )
}

export default App
