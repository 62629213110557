import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Row, Column } from 'components/layout'
import { styles as s } from 'stylesheet'
import { withItem } from './ItemContext'
import Annotation from './Annotation'

const AnnotationsRow = styled.div({
  b: css(s.block, {
    textTransform: 'uppercase',
    padding: '0 0 1rem',
  }),
})

const Annotations = (props) => {
  const { annotations, player: { actions, state } } = props.item

  return (
    <AnnotationsRow as={Row}>
      <Column xs={12}>
        <b>Annotations</b>
        {annotations.map((annotation, index) => (
          <Annotation
            key={index}
            {...annotation}
            onClick={actions.handleCaptionClick}
            playedSeconds={state.playedSeconds}
          />
        ))}
      </Column>
    </AnnotationsRow>
  )
}

export default withItem(Annotations)
