import React from 'react'
// import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { colors } from 'stylesheet'
import { Column, Row } from 'components/layout'
import { withItem } from './ItemContext'
import Player from './Player'
import PlayerControls from './PlayerControls'

const StyledVideoPlayer = styled.div({
  '.video-column': {
    maxHeight: '50vh',
    backgroundColor: colors.black,
  },
})

const Video = (props) => {
  const { cms, player, poster, media } = props.item
  const { actions, state } = player

  const renderPlayer = cms ? <img src={poster} alt='CMS Preview Player' /> : (
    <Player
      media={media}
      poster={poster}
      actions={actions}
      playing={state.playing}
      volume={state.volumne}
      muted={state.muted}
    />
  )

  return (
    <StyledVideoPlayer as={Row} center='xs'>
      <Column className='video-column' xs={12}>
        {renderPlayer}
      </Column>
      <Column xs={12}>
        <PlayerControls />
      </Column>
    </StyledVideoPlayer>
  )
}

export default withItem(Video)
