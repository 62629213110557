import React from 'react'
import { Column, Row } from 'components/layout'
import Markdown from 'components/Markdown'
import styled from '@emotion/styled'
import { find } from 'lodash'
import { withItem } from './ItemContext'
import Player from './Player'
import PlayerControls from './PlayerControls'

const StyledAudioRow = styled.div({
  '.active-annotation': {
    textAlign: 'left',
  },
})

const Audio = (props) => {
  const { annotations, cms, player, poster, media } = props.item
  const { actions, state } = player

  const renderPlayer = cms ? <img src={poster} alt='CMS Preview Player' /> : (
    <Player media={media} poster={poster} actions={actions} playing={state.playing} />
  )

  const activeAnnotation = find(annotations, (ann) => ann.active)

  return (
    <StyledAudioRow as={Row} center='xs'>
      <Column xs={6}>
        {renderPlayer}
      </Column>
      {activeAnnotation && (
        <Column className='active-annotation' xs={6}>
          <Markdown>{activeAnnotation.body}</Markdown>
        </Column>
      )}
      <Column xs={12}>
        <PlayerControls />
      </Column>
    </StyledAudioRow>
  )
}

export default withItem(Audio)
