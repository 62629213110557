import { contentPath } from './constants'

const pagesPath = `${contentPath}/pages`

export default [
  {
    name: 'pages',
    label: 'Pages',
    delete: false,
    files: [
      {
        file: `${pagesPath}/about.md`,
        label: 'About Assotto',
        name: 'about-assotto',
        fields: [
          {
            label: 'Title',
            name: 'title',
            widget: 'string',
          },
          {
            label: 'Featured Image',
            name: 'featuredImage',
            widget: 'image',
          },
          {
            label: 'Featured Image Caption',
            name: 'featuredImageCaption',
            widget: 'string',
          },
          {
            label: 'Body',
            name: 'body',
            widget: 'markdown',
          },
        ],
      },
      {
        file: `${pagesPath}/project.md`,
        label: 'About the Project',
        name: 'project',
        fields: [
          {
            label: 'Title',
            name: 'title',
            widget: 'string',
          },
          {
            label: 'Featured Image',
            name: 'featuredImage',
            widget: 'image',
          },
          {
            label: 'Body',
            name: 'body',
            widget: 'markdown',
          },
        ],
      },
      {
        file: `${pagesPath}/resources.yml`,
        label: 'Resources',
        name: 'resources',
        fields: [
          {
            label: 'Title',
            name: 'title',
            widget: 'string',
          },
          {
            label: 'Resources List',
            name: 'resourcesList',
            widget: 'list',
            fields: [
              {
                label: 'Theme Title',
                name: 'title',
                widget: 'string',
              },
              {
                label: 'Resources',
                name: 'resources',
                widget: 'list',
                fields: [
                  {
                    label: 'Website Title',
                    name: 'title',
                    widget: 'string',
                  },
                  {
                    label: 'Website URL',
                    name: 'url',
                    widget: 'string',
                  },
                  {
                    label: 'Website Description',
                    name: 'body',
                    widget: 'text',
                    required: false,
                  },
                ],
              },
            ],
          },
          {
            label: 'Bibliography',
            name: 'bibliography',
            widget: 'list',
            fields: [
              {
                label: 'Note',
                name: 'note',
                widget: 'text',
              },
            ],
          },
        ],
      },
    ],
  },
]
